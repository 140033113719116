import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import useMediaQueryMaterial from '@mui/material/useMediaQuery';
import { Skeleton } from '@mui/material';

import {
  GET_VARIANT_PRODUCT_QUANTITY,
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_BASE_URL,
} from '../../../Constants/Config';
import LikeIcon from '../../../Assets/Products/FavouriteIcon.svg';
import LikeFillIcon from '../../../Assets/Products/FavouriteFillIcon.svg';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';
import { fetchSingleProductData } from '../../../redux/features/singleProduct/singleProductSlice';
import { fetchFeaturedProducts } from '../../../redux/features/singleProduct/featuredProductsSlice';
import { fetchHighestSellingProducts } from '../../../redux/features/singleProduct/highestSellingProductsSlice';
import Validation from '../../../Constants/Validation';
import SingleProduct from './SingleProduct';
import {
  addToWishlist,
  fetchWishlistData,
  deleteToWishlist,
} from '../../../redux/features/Wishlist/wishlistSlice';
import { addToCart } from '../../../redux/features/Cart/cartSlice';
import { fetchVariantData } from '../../../redux/features/SingleStore/variantSlice';
import ImageZoom from './ImageZoom';
import { fetchAllCartProductDetails } from '../../../redux/features/Cart/cartProductDetailsSlice';
import ProductInfo from './Components/ProductInfo';
import ProductActionButtons from './Components/ProductActionButtons';
import RecentlyViewedProducts from './Components/RecentlyViewedProducts';
import HighestSellingProducts from './Components/HighestSellingProducts';

import 'swiper/css';
import 'swiper/css/virtual';

const SingleProductPage = ({ toggleDrawer }) => {
  const isMobile = useMediaQueryMaterial('(max-width:768px)');
  const min1920 = useMediaQuery({ query: '(min-width: 1920px)' });
  const min1700 = useMediaQuery({ query: '(min-width: 1700px)' });
  const min1500 = useMediaQuery({ query: '(min-width: 1500px)' });
  const min1200 = useMediaQuery({ query: '(min-width: 1200px)' });
  const min1024 = useMediaQuery({ query: '(min-width: 1024px)' });
  const min806 = useMediaQuery({ query: '(min-width: 900px)' });
  const min767 = useMediaQuery({ query: '(min-width: 767px)' });
  const min375 = useMediaQuery({ query: '(min-width: 375px)' });

  const {
    varientTransformer,
    percentOffItem,
    isDecodedData,
    isValidJSON,
    filterProductData,
    AddtoCart,
    checkInCart,
    handleImagesArray,
    filterCartData,
  } = Validation();

  const Navigate = useNavigate();
  const { isGuest } = useSelector((state) => state.authUser);
  const authUserData = useSelector((state) => state.authUser.UserData);
  const dispatch = useDispatch();
  const location = useLocation();

  const userDataState = useSelector((state) => state.authUser);
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );
  const singleProductDataState = useSelector((state) => state.singleProduct);
  const wishlistStateData = useSelector((state) => state.wishlist);
  const cartDataState = useSelector((state) => state.cart.cartData);
  const variantCartDataState = useSelector((state) => state.variantCart);
  const variantDataState = useSelector((state) => state.variant);
  const [selectedImage, setSelectedImage] = useState(0);
  const [singleProduct, setSingleProduct] = useState(null);
  const [defaultVarientList, setdefaultVarientList] = useState([]);
  const [varientList, setVarientList] = useState([]);
  const [singleVariantData, setsingleVariantData] = useState();
  const [singleProductData, setSingleProductData] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const [updateProductQ, setUpdateProductQ] = useState(false);
  const [disableQuantityUpdate, setdisableQuantityUpdate] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [singleStoreData, setSingleStoreData] = useState();
  const [productQuantity, setProductQuantity] = useState(1);
  const [maxProductQuantity, setMaxProductQuantity] = useState(1);
  const [inWishlist, setInWishlist] = useState(false);
  const [oldProductPrice, setOldProductPrice] = useState(0);
  const SingleStoreDataState = useSelector((state) => state.storeProductData);
  const featuredProducts = useSelector((state) => state.featuredProducts);
  const highestSellingProducts = useSelector(
    (state) => state.highestSellingProducts,
  );
  const [changingVariant, setChangingVariant] = useState(false);

  const merchantId = location.pathname.replace('/product/', '');
  const searchParams = new URLSearchParams(location.search);
  const orderMethod = searchParams.get('orderMethod');
  const productId = searchParams.get('product');
  const categoryId = searchParams.get('categoryid');

  const cartKey = `cart_${merchantId}`;
  let dataCartCheck = JSON.parse(localStorage.getItem(cartKey));

  const imagesArray = useMemo(() => {
    return (
      singleProductData &&
      singleProductData.media &&
      handleImagesArray(singleProductData.media)
    );
  }, [singleProductData]);

  const [imageSrc, setImageSrc] = useState(ProductDefaultImage);

  useEffect(() => {
    if (imagesArray && imagesArray.length > 0 && merchantId) {
      const imagePath = imagesArray[selectedImage];
      const img = new Image();
      img.src = `${PRODUCT_IMAGES_BASE_URL}upload/products/${merchantId}/${imagePath}`;

      img.onload = () => {
        setImageSrc(img.src); // Set the valid image
      };

      img.onerror = () => {
        setImageSrc(ProductDefaultImage); // Use default if error
      };
    } else {
      setImageSrc(ProductDefaultImage);
    }

    return () => {
      setImageSrc(() => ProductDefaultImage);
    };
  }, [selectedImage, imagesArray, merchantId]);

  const [isDisabled, setIsDisabled] = useState(false);

  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: min1920
        ? 7
        : min1700
        ? 6
        : min1500
        ? 5
        : min1200
        ? 4
        : min1024
        ? 3
        : min806
        ? 3
        : min767
        ? 2
        : min375
        ? 1
        : 1,
      slidesToScroll: 1,
    };
  }, [min1920, min1700, min1500, min1200, min1024, min806, min767, min375]);

  useEffect(() => {
    if (
      !SingleStoreDataState.loading &&
      SingleStoreDataState?.storeProductData?.all_product
    ) {
      setSingleStoreData(SingleStoreDataState?.storeProductData?.all_product);
    }
  }, [SingleStoreDataState.loading, SingleStoreDataState.storeProductData]);

  useEffect(() => {
    const datawishlist = {
      id: authUserData && authUserData.id,
      merchant_id: merchantId && merchantId,
    };
    if (authUserData && authUserData.id) {
      dispatch(fetchWishlistData(datawishlist));
    }
  }, [authUserData]);

  useEffect(() => {
    const localData = localStorage.getItem('recent' + merchantId);
    if (isValidJSON(isDecodedData(localData))) {
      const storedRecentlyViewed =
        isValidJSON(
          isDecodedData(localStorage.getItem('recent' + merchantId)),
        ) || [];
      if (merchantId && storedRecentlyViewed && singleStoreData) {
        setRecentlyViewed(
          filterProductData(storedRecentlyViewed, singleStoreData),
        );
      }
    }
  }, [singleStoreData, merchantId, orderMethod, productId]);

  useEffect(() => {
    setProductQuantity(1);
    setdefaultVarientList([]);
    setVarientList('');
    const data = {
      merchant_id: merchantId,
      product_id: productId,
      orderMethod: orderMethod,
    };

    dispatch(fetchSingleProductData(data));
    dispatch(fetchFeaturedProducts(data));
    dispatch(fetchHighestSellingProducts(data));
    divScroll();
    goToTop();
  }, [orderMethod, productId, categoryId, merchantId]);

  useEffect(() => {
    if (!userDataState.loading) {
      setAuthUser(userDataState.UserData);
    }
  }, [authUser, userDataState.loading, userDataState.UserData]);

  useEffect(() => {
    if (!singleProductDataState.loading && singleProductDataState) {
      setSingleProduct(singleProductDataState.singleProductData);
      setVarientList(
        singleProductDataState?.singleProductData?.option_data !== null &&
          singleProductDataState.singleProductData?.option_data !== undefined &&
          varientTransformer(
            singleProductDataState.singleProductData?.option_data,
          ),
      );

      if (
        singleProductDataState.singleProductData &&
        singleProductDataState.singleProductData.most_stock_option
      ) {
        const default_variant =
          singleProductDataState.singleProductData.most_stock_option.variant;

        // .split('/')
        // .map((item) => item.trim());

        setdefaultVarientList(default_variant);
      }

      setSingleProductData(
        singleProductDataState.singleProductData &&
          singleProductDataState.singleProductData.product_data,
      );
      setMaxProductQuantity(
        singleProductDataState.singleProductData &&
          singleProductDataState.singleProductData.product_data &&
          singleProductDataState.singleProductData.product_data.quantity,
      );
      handleProductExistIncart();
    }
    if (
      !singleProductDataState.loading &&
      !singleProductDataState.singleProductData
    ) {
      Navigate(-1);
    }
  }, [singleProductDataState]);

  useEffect(() => {
    if (singleProduct) {
      recentlyViewedProduct();
    }
  }, [singleProduct, singleProductData, singleProductDataState]);

  useEffect(() => {
    if (!authUserData) {
      setInWishlist(false);
    }
    // Check if the product is in the wishlist
    else if (
      wishlistStateData &&
      wishlistStateData.wishlistData &&
      wishlistStateData.wishlistData.length > 0
    ) {
      const isProductInWishlist = wishlistStateData.wishlistData.some(
        (wishlistProduct) =>
          wishlistProduct && wishlistProduct.id === productId,
      );
      setInWishlist(isProductInWishlist);
    }
  }, [
    singleProduct,
    wishlistStateData,
    wishlistStateData.wishlistData,
    authUserData,
  ]);

  useEffect(() => {
    // Initialize the selectedOptions state with the first option for each variant
    const initialSelectedOptions = {};
    // console.log('varientList: ', varientList);
    // console.log('defaultVarientList: ', defaultVarientList);

    varientList &&
      varientList.forEach((varient, varientIndex) => {
        const options = varient[`optionsvl${varientIndex + 1}`];
        // console.log('options: ', options);
        if (options && options.length > 0) {
          if (defaultVarientList && defaultVarientList.length > 0) {
            // const matchingElements = defaultVarientList.filter((element) =>
            //   options.includes(element),
            // );
            // initialSelectedOptions[varientIndex] = matchingElements[0]; // Select the first option

            // Handling Variants Based upon Slash involved
            // checking if variant list has multiple types
            // if (
            //   varientList[1] &&
            //   varientList[1].options2 &&
            //   varientList[1].options2 !== ''
            // ) {
            //   const newDefaultVariantList = defaultVarientList
            //     .split('/')
            //     .map((item) => item.trim());
            //   const matchingElements = newDefaultVariantList?.filter(
            //     (element) => options.includes(element),
            //   );
            //   // console.log('matchingElements: ', matchingElements);

            //   initialSelectedOptions[varientIndex] = matchingElements[0]; // Select the first option
            // } else {
            //   const matchingElements =
            //     options.find((option) => option === defaultVarientList) || '';
            //   // console.log('matchingElements: ', matchingElements);

            //   initialSelectedOptions[varientIndex] = matchingElements; // Select the first option
            // }

            // handling variants based upon the Default Variant List
            varientList.forEach((variant, index) => {
              const currentVariant = variant[`optionsvl${index + 1}`];
              if (currentVariant.length > 0) {
                for (let i = 0; i < currentVariant.length; i++) {
                  if (defaultVarientList.includes(currentVariant[i])) {
                    initialSelectedOptions[index] = currentVariant[i];
                  }
                }
              }
            });
          } else {
            initialSelectedOptions[varientIndex] = options[0]; // Select the first option
          }
        }
      });
    // console.log('initialSelectedOptions: ', initialSelectedOptions);
    setSelectedOptions(initialSelectedOptions);
  }, [varientList, productId, singleProduct?.id]);

  useEffect(() => {
    handleProductExistIncart();
    setdisableQuantityUpdate(false);

    if (selectedOptions && selectedOptions[0] !== '') {
      const data = {
        merchant_id: merchantId,
        product_id: productId,
        variant: selectedOptions && Object.values(selectedOptions).join('/'),
      };
      if (data && data.variant) {
        setChangingVariant(() => true);
        dispatch(fetchVariantData(data));
      } else {
        if (changingVariant) {
          setChangingVariant(() => false);
        }
      }
    }
  }, [selectedOptions, productId, cartDataState]);

  useEffect(() => {
    if (!variantDataState.loading && variantDataState.variantData) {
      const { quantity, disable, isstockcontinue, trackqnty } =
        variantDataState.variantData;

      const isOutOfStock =
        (parseFloat(quantity) <= 0 &&
          isstockcontinue === '0' &&
          trackqnty === '1') ||
        disable === '1';

      if (!isOutOfStock && changingVariant) {
        setChangingVariant(() => false);
      }

      // getVeriantData(variantDataState.variantData, variantDataState.variantData?.price ?? 0)
      setsingleVariantData(variantDataState.variantData);
      setOldProductPrice(variantDataState.variantData?.price ?? 0);
    } else {
      setsingleVariantData(false);
    }
  }, [variantDataState, changingVariant]);

  useEffect(() => {
    handleCheckdefaultVariant(singleProductData?.quantity);
  }, [singleProductData?.quantity, disableQuantityUpdate, dataCartCheck]);

  const recentlyViewedProduct = () => {
    const singleProductData = singleProduct && singleProduct.product_data;
    const localviewedProducts = localStorage.getItem('recent' + merchantId);

    if (singleProductData && singleProductData.id != null) {
      const viewedProducts =
        isValidJSON(isDecodedData(localviewedProducts)) || [];
      if (viewedProducts) {
        const isProductInArray = viewedProducts.some(
          (item) =>
            item.productId === singleProductData.id &&
            item.categoryId === singleProductData.cotegory,
        );

        if (!isProductInArray) {
          const newProduct = {
            productId: singleProductData.id,
            categoryId: singleProductData.cotegory,
            show_type: singleProductData.show_type,
          };

          viewedProducts.unshift(newProduct);
          if (viewedProducts.length > 10) {
            viewedProducts.pop();
          }

          localStorage.setItem(
            'recent' + merchantId,
            btoa(JSON.stringify(viewedProducts)),
          );
        }
      } else {
        localStorage.removeItem('recent' + merchantId);
      }
    }
  };

  const divScroll = () => {
    const mydiv = document.getElementById('SingleProduct-Description');
    mydiv.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // fn for adding product in wishlist
  const handleAddToWishlist = () => {
    if (authUserData && authUserData.id) {
      const data = {
        user_id: authUserData && authUserData.id,
        merchants_id: merchantId,
        product_id: productId,
        category_id: categoryId,
      };

      dispatch(addToWishlist(data));
      setInWishlist(true);

      const datawishlist = {
        id: authUserData && authUserData.id,
        merchant_id: merchantId && merchantId,
      };
      setTimeout(() => {
        if (authUserData && authUserData.id) {
          dispatch(fetchWishlistData(datawishlist));
        }
      }, 1000);
    } else {
      Navigate('/customer-login', {
        state: {
          merchantId: merchantId,
        },
      });
    }
  };

  // fn for deleting product from wishlist
  const handleDeleteToWishlist = () => {
    if (authUserData && authUserData.id && productId && merchantId) {
      const data = {
        merchant_id: merchantId,
        user_id: authUserData && authUserData.id,
        product_id: productId,
      };
      dispatch(deleteToWishlist(data));
      setInWishlist(false);
    }
  };

  const handleAddToCart = () => {
    // Collect selected options for the product
    const selectedProductOptions = [];
    // console.log('varientList: ', varientList);
    // console.log('selectedOptions: ', selectedOptions);
    for (let i = 0; i < varientList.length; i++) {
      if (selectedOptions[i]) {
        selectedProductOptions.push({
          varientName: varientList[i][`options${i + 1}`],
          varientVl: selectedOptions[i],
        });
      }
    }

    const cartKey = `cart_${merchantId}`;
    let cartData = JSON.parse(localStorage.getItem(cartKey)) || [];

    const cartProduct = {
      cartProductId: new Date().getTime(),
      productId: productId,
      categoryId: categoryId,
      quantity: productQuantity,
      varients: selectedProductOptions,
      allVarients: varientList,
      isDisabled: false,
      addedOn:
        cartData.length > 0 ? cartData[cartData.length - 1].addedOn + 1 : 1,
    };
    // console.log('cartProduct: ', cartProduct);

    // Log the cartProduct for debugging

    // Add the product to the cart
    setUpdateProductQ(true);

    const newProducts = [];
    for (let i = 0; i < productQuantity; i++) {
      newProducts.push({
        ...cartProduct,
        addedOn: cartProduct.addedOn + i,
        quantity: 1,
      });
    }

    // console.log('productQuantity: ', productQuantity);
    // console.log('newProducts: ', newProducts);

    // dispatch(addToCart([cartProduct]));
    dispatch(addToCart([...newProducts]));
    AddtoCart(cartProduct, merchantId);

    cartData = JSON.parse(localStorage.getItem(cartKey)) || [];

    if (cartData) {
      const productIdsString = cartData.map((item) => item.productId).join(',');

      const data = {
        merchant_id: merchantId,
        product_id: productIdsString,
      };
      // console.log('in single product page...');
      dispatch(fetchAllCartProductDetails(data));
    }
  };

  const handleProductExistIncart = () => {
    if (selectedOptions && Object.keys(selectedOptions).length > 0) {
      const selectedProductOptions = [];

      for (let i = 0; i < varientList.length; i++) {
        if (selectedOptions[i]) {
          selectedProductOptions.push({
            varientName: varientList[i][`options${i + 1}`],
            varientVl: selectedOptions[i],
          });
        }
      }

      const cartProduct = {
        productId: productId,
        categoryId: categoryId,
        quantity: productQuantity,
        varients: selectedProductOptions,
      };

      if (checkInCart(cartProduct, merchantId)) {
        setUpdateProductQ(true);
        // return true;
      } else {
        setUpdateProductQ(false);
        // return false;
      }
    } else {
      const cartProduct = {
        productId: productId,
        categoryId: categoryId,
        quantity: productQuantity,
        varients: [],
      };

      if (checkInCart(cartProduct, merchantId)) {
        setUpdateProductQ(true);
        // return true;
      } else {
        setUpdateProductQ(false);
        // return false;
      }
    }
  };

  const handleRadioChange = (variantIndex, option) => {
    setProductQuantity(1);
    // console.log('variantIndex: ', variantIndex);
    // console.log('option: ', option);
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [variantIndex]: option,
    }));
  };

  // for Non variant products
  const handleCheckdefaultVariant = (quantity) => {
    if (
      quantity >= 1 &&
      parseInt(quantity) == productQuantity &&
      singleProductData?.isstockcontinue !== '1' &&
      singleProductData?.trackqnty === '1' &&
      singleProductData?.isvarient !== '1'
    ) {
      setdisableQuantityUpdate(true);
    }
  };

  // increment or decrement button function
  const handleUpdateQuantity = async (quantity, type) => {
    // console.log('varientList: ', varientList);
    if (varientList && Array.isArray(varientList) && varientList.length >= 1) {
      // 1. check total qty in cart
      // 2. check if total qty is same as PQ qty then disable this button.
      const productPurchaseQTY = +variantDataState.variantData?.purchase_qty;
      const productId = variantDataState.variantData?.product_id;
      const productVariantId = variantDataState.variantData?.id;

      // console.log('variantDataState: ', variantDataState);

      const getCartProducts = () => {
        if (
          cartDataState &&
          variantCartDataState?.variantCartData &&
          cartProductDetails
        ) {
          const data = filterCartData(
            cartDataState,
            variantCartDataState?.variantCartData,
            cartProductDetails,
          );

          return data ? data : [];
        }
      };

      const cartProducts = getCartProducts();
      // console.log('cartProducts: ', cartProducts);

      const variantCartProductsInCart = [];
      variantCartDataState.variantCartData?.forEach((prod) => {
        cartProducts.forEach((cartProduct) => {
          if (
            prod.id === cartProduct.variantId &&
            prod.product_id === cartProduct.productId
          ) {
            variantCartProductsInCart.push(prod);
          }
        });
      });
      // console.log('variantCartProductsInCart: ', variantCartProductsInCart);

      // other same pq products
      // const samePQProducts = variantCartProductsInCart.filter(
      //   (prod) =>
      //     prod.product_id === productId &&
      //     +prod.purchase_qty === productPurchaseQTY &&
      //     prod.id !== productVariantId,
      // );

      const otherSamePQProducts = variantCartProductsInCart.filter(
        (prod) =>
          prod.product_id === productId &&
          +prod.purchase_qty === productPurchaseQTY &&
          prod.id !== productVariantId,
      );
      // console.log('otherSamePQProducts: ', otherSamePQProducts);

      // 1. variant cart products.. - group by PQ products.
      // 2. get total qty added in cart.. if same as PQ. then disable current selected product.
      if (otherSamePQProducts.length > 0) {
        // let totalQtyInCart = 0;

        const sameProductIds = otherSamePQProducts.map((item) => item.id);
        const sameProductFromCart = cartProducts.filter((item) =>
          sameProductIds.includes(item.variantId),
        );
        // console.log('sameProductIds: ', sameProductIds);
        // console.log('sameProductFromCart: ', sameProductFromCart);

        let totalQtyInCart = sameProductFromCart.reduce(
          (acc, curr) => (acc += +curr.selectedQuantity),
          0,
        );
        // console.log('totalQtyInCart: ', totalQtyInCart);

        // samePQProducts.forEach((prod) => {
        //   cartProducts.forEach((cartProduct) => {
        //     if (cartProduct.variantId === prod.id) {
        //       totalQtyInCart = totalQtyInCart + cartProduct.selectedQuantity;
        //     }
        //   });
        // });

        if (type === 'increment') {
          totalQtyInCart = totalQtyInCart - 1;
        }

        // 1. if already in cart products qty is same as PQ limit.. disable this current product.
        if (totalQtyInCart + quantity === +productPurchaseQTY) {
          setdisableQuantityUpdate(true);
          return;
        } else {
          setdisableQuantityUpdate(false);
        }
      }

      // if product passes Purchase quantity conditions then checking its available qty from backend.
      const data = {
        merchant_id: merchantId,
        product_id: productId,
        variant_id: singleVariantData.id,
        quantity: quantity,
      };
      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (
          response.data.status === 200 &&
          parseInt(response.data.result.quantity) >= quantity
        ) {
          setProductQuantity(quantity);
          setdisableQuantityUpdate(false);
        } else if (response.data.status === 400) {
          setdisableQuantityUpdate(true);
        }
      } catch (error) {
        return new Error('Something went wrong');
      }
    } else {
      const data = {
        merchant_id: merchantId,
        product_id: productId,
        variant_id: '',
        quantity: quantity,
      };

      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (
          response.data.status === 200 &&
          parseInt(response.data.result.quantity) >= quantity
        ) {
          setProductQuantity(quantity);
          setdisableQuantityUpdate(false);
        } else if (response.data.status === 400) {
          setdisableQuantityUpdate(true);
        }
      } catch (error) {
        return new Error('Something went wrong');
      }
    }
  };

  // Product conditions for checking if the product is disabled or out of stock
  const productConditions = useMemo(() => {
    const isQuantityZeroOrLess = singleVariantData
      ? parseFloat(singleVariantData?.quantity) <= 0
      : singleProductData
      ? parseFloat(singleProductData?.quantity) <= 0
      : false;

    const productIsDisabled = singleVariantData
      ? singleVariantData?.disable === '1'
      : singleProductData
      ? singleProductData?.disable === '1'
      : false;

    const isProductStockContinued = singleVariantData
      ? singleVariantData?.isstockcontinue === '0'
      : singleProductData
      ? singleProductData?.isstockcontinue === '0'
      : false;

    const productTrackQuantity = singleVariantData
      ? singleVariantData?.trackqnty === '1'
      : singleProductData
      ? singleProductData?.trackqnty === '1'
      : false;

    return {
      isQuantityZeroOrLess,
      productIsDisabled,
      isProductStockContinued,
      productTrackQuantity,
    };
  }, [singleVariantData, singleProductData]);

  const showLimitedQtyText = useMemo(() => {
    const bool = (data) => {
      return (
        data?.isstockcontinue === '0' &&
        data?.trackqnty === '1' &&
        data?.disable === '0'
      );
    };

    let pqQty = 0;
    if (singleVariantData) {
      if (singleVariantData.purchase_qty) {
        pqQty = parseFloat(singleVariantData.purchase_qty);
      }
    } else {
      if (singleProductData && singleProductData.purchase_qty) {
        pqQty = parseFloat(singleProductData.purchase_qty);
      }
    }

    let qty = 0;
    if (singleVariantData) {
      if (singleVariantData.quantity) {
        qty = parseFloat(singleVariantData?.quantity);
      }
    } else {
      if (singleProductData && singleProductData.quantity) {
        qty = parseFloat(singleProductData?.quantity);
      }
    }

    let maxQty = 0;

    // if product qty is greater than Purchase Qty
    if (qty > pqQty && pqQty < 5 && pqQty > 0) {
      maxQty = pqQty;
    }

    // if Purchase Qty is greater than actual Qty
    if (pqQty >= qty && qty < 5 && qty > 0) {
      maxQty = qty;
    }

    // if Purchase Qty is not available and actual Qty is > 0 and < 5
    if (pqQty <= 0 && qty > 0 && qty < 5) {
      maxQty = qty;
    }

    return {
      bool: bool(singleVariantData ? singleVariantData : singleProductData),
      quantity: maxQty > 0 ? maxQty : 0,
    };
  }, [singleProductData, singleVariantData]);

  // if product qty is 0
  // if product stock continue is false
  // if product track quantity is on
  // then that product is out of stock
  const productIsOutOfStock = useMemo(() => {
    return (
      productConditions.isQuantityZeroOrLess &&
      productConditions.isProductStockContinued &&
      productConditions.productTrackQuantity
    );
  }, [productConditions]);

  // If product is disabled,
  // if its out of stock,
  const outOfStockCondition = useMemo(() => {
    return (
      productConditions.productIsDisabled ||
      productIsOutOfStock ||
      (!singleVariantData && productIsOutOfStock) ||
      (isDisabled && !updateProductQ)
    );
  }, [
    productConditions.productIsDisabled,
    singleVariantData,
    productIsOutOfStock,
    isDisabled,
    updateProductQ,
  ]);

  return (
    <>
      <div className="quickvee-single-product-page">
        {singleProductDataState.loading ? (
          <div className="quickvee-single-product-top-section">
            <div className="quickvee-single-product-image-section">
              <Skeleton
                variant="rounded"
                width={400}
                height={400}
                style={{ margin: '10px' }}
              />

              <div className="quickvee-single-product-images">
                <div className="skeleton-for-loading">
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={80}
                    style={{ marginRight: '5px' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={80}
                    style={{ marginRight: '5px' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={80}
                    style={{ marginRight: '5px' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={80}
                    style={{ marginRight: '5px' }}
                  />
                </div>
              </div>
            </div>
            <div
              className="quickvee-single-product-detail-section"
              id="SingleProduct-Description"
            >
              <Skeleton variant="rounded" width={400} height={40} />
              <Skeleton
                variant="rounded"
                width={200}
                height={40}
                style={{ marginTop: '5px' }}
              />
            </div>
          </div>
        ) : (
          <div className="quickvee-single-product-top-section">
            {/* product image section */}
            <div className="quickvee-single-product-image-section">
              <div className="quickvee-single-product-main-image">
                {singleVariantData &&
                percentOffItem(
                  singleVariantData?.price,
                  singleVariantData?.compare_price,
                ) ? (
                  <span
                    className="discount-tag"
                    style={{ background: '#373737' }}
                  >
                    {' '}
                    {percentOffItem(
                      singleVariantData?.price,
                      singleVariantData?.compare_price,
                    ) + '%  Off'}{' '}
                  </span>
                ) : (singleVariantData === false ||
                    singleVariantData === null) &&
                  singleProductData &&
                  percentOffItem(
                    singleProductData?.price,
                    singleProductData?.compare_price,
                  ) ? (
                  <span
                    className="discount-tag"
                    style={{ background: '#373737' }}
                  >
                    {' '}
                    {percentOffItem(
                      singleProductData?.price,
                      singleProductData?.compare_price,
                    ) + '%  Off'}{' '}
                  </span>
                ) : (
                  <span></span>
                )}
                <div className="quickvee-single-product-div">
                  {singleProductData && (
                    <ImageZoom
                      merchant_id={singleProductData?.merchant_id}
                      image={imageSrc}
                    />
                  )}
                </div>
                {isGuest !== 'yes' &&
                  (inWishlist ? (
                    <img
                      src={LikeFillIcon}
                      alt="add-to-wishlist"
                      onClick={() => handleDeleteToWishlist()}
                      className="quickvee-single-product-add-to-wishlist-icon"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src={LikeIcon}
                      alt="add-to-wishlist"
                      loading="lazy"
                      className="quickvee-single-product-add-to-wishlist-icon"
                      onClick={() => handleAddToWishlist()}
                    />
                  ))}
              </div>
              <div className="quickvee-single-product-images">
                {imagesArray &&
                  Array.isArray(imagesArray) &&
                  imagesArray?.length >= 1 &&
                  imagesArray.map((image, index) => (
                    <div
                      key={index}
                      onClick={() => setSelectedImage(index)}
                      className="quickvee-single-product-images-single-image"
                      style={
                        selectedImage === index
                          ? { border: '1px solid #232323' }
                          : {}
                      }
                    >
                      <img
                        // src={`${BASE_URL_SANDBOX}upload/products/${singleProductData?.merchant_id}/${image}`}
                        src={`${PRODUCT_IMAGES_BASE_URL}upload/products/${singleProductData?.merchant_id}/${image}`}
                        onError={(e) => {
                          e.target.src = ProductDefaultImage;
                        }}
                        alt="product"
                        loading="lazy"
                      />
                    </div>
                  ))}
              </div>
            </div>
            {/* product details section */}
            <div
              className="quickvee-single-product-detail-section"
              id="SingleProduct-Description"
            >
              <ProductInfo
                singleProductData={singleProductData}
                varientList={varientList}
                singleVariantData={singleVariantData}
                variantDataState={variantDataState}
                oldProductPrice={oldProductPrice}
                outOfStockCondition={outOfStockCondition}
              />
              {/* variants section */}
              {varientList && Array.isArray(varientList) ? (
                <div className="quickvee-single-product-features">
                  {varientList.map((varient, index) => {
                    const optionsName = varient[`options${index + 1}`];
                    const options = varient[`optionsvl${index + 1}`];
                    if (
                      optionsName &&
                      optionsName.length >= 1 &&
                      options &&
                      options.length >= 1
                    ) {
                      return (
                        <div
                          key={index}
                          className="quickvee-single-product-single-features"
                        >
                          <p>{optionsName}</p>
                          <div className="single-product-single-features-selection">
                            {options.map((option, optionIndex) => (
                              <div key={optionIndex}>
                                <input
                                  type="radio"
                                  id={`radio-${index}-${optionIndex}`}
                                  name={`variant-${index}`} // Ensure each group of radio buttons has a unique name
                                  value={option}
                                  checked={
                                    selectedOptions[index] === option ||
                                    (selectedOptions[index] === undefined &&
                                      optionIndex === 0)
                                  }
                                  onChange={() => {
                                    handleRadioChange(index, option);
                                  }}
                                />
                                <label
                                  htmlFor={`radio-${index}-${optionIndex}`}
                                >
                                  {option}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}

              {showLimitedQtyText.bool && showLimitedQtyText.quantity > 0 ? (
                <span className="limited-quantity-text">
                  Only {showLimitedQtyText.quantity} left!
                </span>
              ) : null}

              <ProductActionButtons
                productConditions={productConditions}
                singleVariantData={singleVariantData}
                updateProductQ={updateProductQ}
                toggleDrawer={toggleDrawer}
                productQuantity={productQuantity}
                handleUpdateQuantity={handleUpdateQuantity}
                handleAddToCart={handleAddToCart}
                disableQuantityUpdate={disableQuantityUpdate}
                singleProductDataState={singleProductDataState}
                changingVariant={changingVariant}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                outOfStockCondition={outOfStockCondition}
                productIsOutOfStock={productIsOutOfStock}
              />
              {singleProductData?.description.length > 0 && (
                <div className="quickvee-single-product-detail-description">
                  <span>Description</span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: singleProductData?.description,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Featured Products */}
        {featuredProducts &&
          featuredProducts.featuredProductsData &&
          featuredProducts.featuredProductsData.length > 0 && (
            <div className="quickvee-highest-selling-product-section">
              <span>Feature Products</span>
              <Slider {...settings}>
                {featuredProducts.featuredProductsData.map((product) => (
                  <SingleProduct
                    key={product.id}
                    product={product}
                    categoryId={product.cotegory}
                    merchantId={merchantId}
                  />
                ))}
              </Slider>
            </div>
          )}

        {/* Highest selling Products */}
        <HighestSellingProducts
          highestSellingProducts={
            highestSellingProducts.highestSellingProductsData
          }
          isMobile={isMobile}
          merchantId={merchantId}
          settings={settings}
        />

        {/* Recently Viewed Products */}
        <RecentlyViewedProducts
          recentlyViewed={recentlyViewed}
          isMobile={isMobile}
          merchantId={merchantId}
          settings={settings}
        />
      </div>
    </>
  );
};

export default SingleProductPage;
