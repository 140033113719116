import { useEffect, useMemo, useState } from 'react';
import Validation from '../../../Constants/Validation';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCouponData,
  removeCoupon,
} from '../../../redux/features/Coupon/couponSlice';
import { BASE_URL_SANDBOX, USED_LP_POINTS } from '../../../Constants/Config';
import axios from 'axios';
import {
  deductCouponDiscount,
  deductLoyaltyPointsDiscount,
  getRoundValue,
  handleBogoDeals,
  prioritizeNumber,
} from '../../../utils';

const CouponTipSectionLogic = ({ merchantId }) => {
  const dispatch = useDispatch();
  const { filterCartData } = Validation();

  const cartDataState = useSelector((state) => state?.cart?.cartData);
  const storeProductDataState = useSelector((state) => state?.storeProductData);
  const storeDataState = useSelector((state) => state?.singleStores);
  const VarientDataState = useSelector((state) => state?.variantCart);
  const taxDetailData = useSelector((state) => state?.taxDetail?.taxDetailData);
  const AuthUserState = useSelector((state) => state?.authUser?.UserData);
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );
  const lp_sc_user_data = useSelector(
    (state) => state?.lpscPayment?.LpscPaymentData,
  );
  const authUserData = useSelector((state) => state.authUser);
  const bogoList = useSelector((state) => state.bogoList);

  const [orderSummaryData, setOrderSummaryData] = useState();
  const [cart, setCart] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [storeproductData, setStoreProductData] = useState();
  const [singleStoreData, setSingleStoreData] = useState();
  const [discountAmt, setdiscountAmt] = useState(0);
  const [percentageDeducLoy, setpercentageDeducLoy] = useState(0);
  const [percentageDeducCoupon, setpercentageDeducCoupon] = useState(0);
  const [amountDeducCoupon, setamountDeducCoupon] = useState(0);
  const [varientData, setVarientData] = useState([]);
  const [cartData, setCartAllData] = useState([]);
  const [usedLoyaltyPointApiUserData, setusedLoyaltyPointApiUserData] =
    useState('');
  const [taxValues, settaxValues] = useState(null);
  const [values, setValues] = useState({
    coupon: '',
    loyaltyPoint: '',
    tip: '',
    tipcustom: '',
    storeCredit: '',
    usedLoyaltyPoint: '',
    usedStoreCredit: '',
    tipRemoved: false,
    errors: {
      coupon: '',
      loyaltyPoint:
        parseFloat(lp_sc_user_data?.loyalty_program?.min_points_redemption) >
        parseFloat(lp_sc_user_data?.customer_lp_point?.cus_loyality_points)
          ? 'You do not have enough points to use'
          : '',
      disableLoyaltyPoint: false,
      storeCredit: '',
      tip: '',
      tipcustom: '',
    },
    cursorposition: {
      coupon: 0,
      loyaltyPoint: 0,
      tip: 0,
      tipcustom: 0,
      storeCredit: 0,
    },
  });

  useEffect(() => {
    setCart(cartDataState);
  }, [cartDataState]);

  useEffect(() => {
    if (
      !storeProductDataState.loading &&
      !VarientDataState.loading &&
      !storeDataState.loading &&
      storeDataState &&
      storeDataState.storesData &&
      storeDataState.storesData.store_data
    ) {
      setStoreProductData(storeProductDataState.storeProductData);
      setVarientData(VarientDataState.variantCartData);
      setSingleStoreData(storeDataState.storesData.store_data);
    }
  }, [
    storeProductDataState.loading,
    storeDataState.loading,
    storeDataState.storesData,
    VarientDataState.loading,
    VarientDataState.variantCartData,
  ]);

  // setting cart data
  useEffect(() => {
    if (
      cartDataState &&
      VarientDataState?.variantCartData &&
      cartProductDetails
    ) {
      const data = filterCartData(
        cartDataState,
        varientData,
        cartProductDetails,
      );

      setCartAllData(data);
    }
  }, [storeproductData, cartDataState, cart, varientData]);

  const calculateTotalPrice = async (cartData) => {
    const total = await cartData.reduce((acc, product) => {
      return parseFloat(acc) + parseFloat(product.price);
    }, 0);
    return setTotalPrice(total.toFixed(2));
  };

  const taxCalculationLogic = (newCartItems, taxes, lpAmount) => {
    const taxData = {};

    // is coupon applied
    const couponApplied =
      percentageDeducCoupon &&
      !isNaN(percentageDeducCoupon) &&
      percentageDeducCoupon > 0;

    // is Loyalty points applied
    const loyaltyPointsApplied =
      percentageDeducLoy &&
      !isNaN(percentageDeducLoy) &&
      percentageDeducLoy > 0;

    // check for penny discrepancies
    const doPennyFix = couponApplied || loyaltyPointsApplied;

    // penny fix logic goes here...
    if (doPennyFix) {
      newCartItems = pennyFixLogic(
        newCartItems,
        percentageDeducCoupon,
        percentageDeducLoy,
        lpAmount,
      );
    }

    // tax calculation logic
    newCartItems.forEach((item) => {
      taxes.forEach((tax) => {
        if (item?.taxesAplicable && item?.taxesAplicable?.includes(tax.id)) {
          let newItemPrice = item.pennyFixedPrice
            ? item.pennyFixedPrice
            : item.price
            ? item.price
            : 0;

          const taxAmount = (percent) =>
            (parseFloat(percent) * parseFloat(newItemPrice)) / 100;

          if (taxData[tax.title]) {
            const taxExists = parseFloat(taxData[tax.title]);
            const a = parseFloat(taxAmount(tax.percent));
            const b = getRoundValue(a);
            taxData[tax.title] = parseFloat(
              parseFloat(parseFloat(b) + taxExists),
            );
          } else {
            const a = parseFloat(taxAmount(tax.percent));
            const b = getRoundValue(a);
            taxData[tax.title] = parseFloat(parseFloat(b));
          }
        }
      });
    });

    const taxesObj = {};
    for (let key in taxData) {
      taxesObj[key] = taxData[key] ? getRoundValue(taxData[key]) : '0.00';
    }

    // console.log('taxesObj: ', taxesObj);
    settaxValues(taxesObj);
  };

  // calculating Tax
  useEffect(() => {
    if (cartData && taxDetailData) {
      calculateTotalPrice(cartData);

      if (bogoList.bogoList.length > 0) {
        const afterBogoCart = handleBogoDeals(cartData, bogoList.bogoList);
        taxCalculationLogic(
          afterBogoCart,
          taxDetailData,
          values.usedLoyaltyPoint,
        );
      } else {
        taxCalculationLogic(cartData, taxDetailData, values.usedLoyaltyPoint);
      }
    }
  }, [
    bogoList,
    cartData,
    percentageDeducLoy,
    percentageDeducCoupon,
    values.loyaltyPoint,
    values.usedLoyaltyPoint,
    taxDetailData,
  ]);

  const lpPointsCalculation = (newCartItems, taxes, lpAmount) => {
    // penny fix logic goes here...
    const couponApplied =
      percentageDeducCoupon &&
      !isNaN(percentageDeducCoupon) &&
      percentageDeducCoupon > 0;

    const loyaltyPointsApplied =
      percentageDeducLoy &&
      !isNaN(percentageDeducLoy) &&
      percentageDeducLoy > 0;

    const doPennyFix = couponApplied || loyaltyPointsApplied;

    if (doPennyFix) {
      newCartItems = pennyFixLogic(
        newCartItems,
        percentageDeducCoupon,
        percentageDeducLoy,
        lpAmount,
      );
    }

    const newCart = newCartItems.map((item) => {
      let newItem = { ...item };

      const newItemPrice = item.pennyFixedPrice
        ? item.pennyFixedPrice
        : item.price
        ? item.price
        : 0;

      item?.taxesAplicable &&
        item?.taxesAplicable?.forEach((itemTax) => {
          const taxFound = taxes?.find((tax) => tax.id === itemTax);
          if (taxFound) {
            if (newItem.totalTaxAmount) {
              const taxApplied =
                (parseFloat(newItemPrice) * parseFloat(taxFound.percent)) / 100;
              const taxRoundedValue = getRoundValue(taxApplied);
              newItem.totalTaxAmount =
                parseFloat(newItem.totalTaxAmount) +
                parseFloat(taxRoundedValue);
            } else {
              const taxApplied =
                (parseFloat(newItemPrice) * parseFloat(taxFound.percent)) / 100;
              const taxRoundedValue = getRoundValue(taxApplied);
              newItem.totalTaxAmount = parseFloat(taxRoundedValue);
            }
          }
        });

      return { ...newItem };
    });

    const getTotal = (finalizedCart, type) => {
      let total = finalizedCart
        .filter((cartItem) => cartItem.product.earn_point === type)
        .reduce(
          (acc, curr) =>
            (acc +=
              (doPennyFix ? curr.pennyFixedPrice : curr.price) +
              (curr.totalTaxAmount || 0)),
          0,
        );
      return total;
    };

    const earnPointsAmount = getTotal(newCart, '1');
    const nonEarnPointsAmount = getTotal(newCart, '0');

    const storeCredit =
      values.usedStoreCredit && parseFloat(values.usedStoreCredit) > 0
        ? parseFloat(values.usedStoreCredit)
        : 0;

    let earnPoints = 0;

    if (storeCredit >= nonEarnPointsAmount) {
      let amountToDeduct = storeCredit - nonEarnPointsAmount;
      earnPoints = earnPointsAmount - amountToDeduct;
    } else {
      earnPoints = earnPointsAmount;
    }

    // sc - 100
    // earn amt - 82.68
    // non earn amt - 86.71

    /*
    1) sc is greater than non earn amount or not..
    2) if it is greater than.. i will have to minus from earn amount also.. 
    3) else earn amount will be clear as earnPointsAmount. 
    5) if sc is more than non earn amt, then first minus non earn amt with SC and then again minus that with earn amount. 
    */

    const usePoints = newCart
      .filter((item) => item.product.use_point === '1')
      .reduce(
        (acc, curr) =>
          (acc +=
            (doPennyFix ? curr.pennyFixedPrice : curr.price) +
            (curr.totalTaxAmount || 0)),
        0,
      );

    const points = {
      usePoints:
        parseFloat(usePoints) > 0 && loyaltyPointsApplied
          ? parseFloat(usePoints).toFixed(2)
          : 0,
      earnPoints:
        parseFloat(earnPoints) > 0 ? parseFloat(earnPoints).toFixed(2) : 0,
    };
    // console.log('points: ', points);
    return points;
  };

  // LP calculation
  useEffect(() => {
    let points = {};
    let bogoDiscount = 0;
    let subtotalAfterBogo = 0;

    if (bogoList.bogoList.length > 0) {
      const afterBogoCart = handleBogoDeals(cartData, bogoList.bogoList);

      // calculating new subtotal after bogo and bogo discount
      const values = afterBogoCart.reduce(
        (acc, curr) => {
          if (curr.bogoDiscountApplied) {
            const discount = curr.product.price - curr.price;
            return (acc = {
              ...acc,
              discount: parseFloat(acc.discount) + parseFloat(discount),
              total: parseFloat(acc.total) + parseFloat(curr.price),
            });
          } else {
            return (acc = {
              ...acc,
              total: parseFloat(acc.total) + parseFloat(curr.price),
            });
          }
        },
        { discount: 0, total: 0 },
      );

      subtotalAfterBogo = parseFloat(values.total).toFixed(2);
      bogoDiscount = parseFloat(values.discount).toFixed(2);

      points = lpPointsCalculation(
        afterBogoCart,
        taxDetailData,
        usedLoyaltyPointApiUserData?.used_amt,
      );
    } else {
      points = lpPointsCalculation(
        cartData,
        taxDetailData,
        usedLoyaltyPointApiUserData?.used_amt,
      );
    }

    const dataObj = {
      cartItems: Array.isArray(cartData) && cartData.length,
      cartProductSum: parseFloat(totalPrice),
      subtotalAfterBogo: parseFloat(subtotalAfterBogo),
      singleStoreData: singleStoreData,
      taxData: taxValues,
      taxDetailApiData: taxDetailData,
      bogoDiscount,
      lp_sc_data: {
        enable_loyalty: lp_sc_user_data?.loyalty_program?.enable_loyalty,
        store_credit_amt: values.usedStoreCredit,
        spend_loyality_amount: usedLoyaltyPointApiUserData?.used_amt,
        spend_loyality_points: usedLoyaltyPointApiUserData?.used_points,
        total_lp_apply_amt: points?.usePoints,
        total_lp_earn_amt: points?.earnPoints,
      },
      discountAmt: discountAmt,
      percentageDeducCoupon: percentageDeducCoupon ?? 0,
      percentageDeducLoy: percentageDeducLoy ?? 0,
    };

    setOrderSummaryData(dataObj);
  }, [
    cartData,
    totalPrice,
    singleStoreData,
    taxValues,
    percentageDeducLoy,
    values.usedStoreCredit,
    values.loyaltyPoint,
    discountAmt,
    // additional dependencies....
    amountDeducCoupon,
    lp_sc_user_data?.loyalty_program?.enable_loyalty,
    percentageDeducCoupon,
    taxDetailData,
    usedLoyaltyPointApiUserData?.used_amt,
    usedLoyaltyPointApiUserData?.used_points,
    bogoList.bogoList,
  ]);

  const discountPrice = (price) =>
    price - (price * (percentageDeducCoupon ? percentageDeducCoupon : 0)) / 100;

  const { validateCoupon, getCurrentDateTime, pennyFixLogic } = Validation();
  const [couponData, setcouponData] = useState('');
  const couponDataState = useSelector((state) => state.coupon);

  useEffect(() => {
    if (couponDataState && !couponDataState.loading) {
      if (couponDataState?.errorMessage !== '') {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: couponDataState.errorMessage,
          },
        }));
      } else if (
        parseFloat(couponDataState?.couponData?.min_amount) >
        parseFloat(totalPrice)
      ) {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: `Minimum order amount should be $${parseFloat(
              couponDataState?.couponData?.min_amount,
            ).toFixed(2)}`,
          },
        }));
      } else {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: '',
          },
        }));

        // flag 0 - Percent Coupon & flag - 1 Amount coupon
        if (couponDataState?.couponData?.flag === '0') {
          // const couponAppliedAmt =
          //   parseFloat(totalPrice) -
          //   parseFloat(
          //     (parseFloat(totalPrice) *
          //       parseFloat(couponDataState?.couponData?.discount)) /
          //       100,
          //   );
          // console.log('couponAppliedAmt: ', couponAppliedAmt);

          // const couponAppliedPercent =
          //   100 - (couponAppliedAmt / parseFloat(totalPrice)) * 100;
          // console.log('couponAppliedPercent: ', couponAppliedPercent);
          // setpercentageDeducCoupon(couponAppliedPercent);
          // setamountDeducCoupon(parseFloat(totalPrice) - couponAppliedAmt);

          // ------------------- New Code
          const maximumDiscountValue =
            parseFloat(couponDataState?.couponData?.maximum_discount) > 0
              ? parseFloat(couponDataState?.couponData?.maximum_discount)
              : 0;

          const currentDiscountValue = parseFloat(
            (parseFloat(totalPrice) *
              parseFloat(couponDataState?.couponData?.discount)) /
              100,
          );

          // console.log('currentDiscountValue: ', currentDiscountValue);
          // console.log('maximumDiscountValue: ', maximumDiscountValue);

          if (
            currentDiscountValue > maximumDiscountValue &&
            maximumDiscountValue > 0
          ) {
            // dont allow to set the Main Percent keep according to the Maximum discount
            // console.log('AS PER MAX DISCOUNT....');
            const newPercentageDiscount =
              (parseFloat(maximumDiscountValue) / parseFloat(totalPrice)) * 100;
            setpercentageDeducCoupon(newPercentageDiscount);

            // console.log('inside 1 -------------------------');
            const deductCouponAmount =
              (parseFloat(totalPrice) * parseFloat(newPercentageDiscount)) /
              100;
            // console.log('newPercentageDiscount: ', newPercentageDiscount);
            // console.log('deductCouponAmount: ', deductCouponAmount);

            setamountDeducCoupon(
              parseFloat(parseFloat(deductCouponAmount).toFixed(2)),
            );
          } else {
            // console.log('inside 2 -------------------------');
            const discountAmount =
              parseFloat(totalPrice) - currentDiscountValue;
            const couponAppliedPercent =
              100 - (discountAmount / parseFloat(totalPrice)) * 100;
            // console.log('heheheh couponAppliedPercent: ', couponAppliedPercent);
            setpercentageDeducCoupon(couponAppliedPercent);

            const deductCouponAmount =
              (parseFloat(totalPrice) * parseFloat(couponAppliedPercent)) / 100;
            // console.log('deductCouponAmount: ', deductCouponAmount);
            // setamountDeducCoupon(parseFloat(deductCouponAmount));
            setamountDeducCoupon(
              parseFloat(parseFloat(deductCouponAmount).toFixed(2)),
            );
          }
        } else {
          const couponAppliedAmt =
            parseFloat(totalPrice) -
            parseFloat(couponDataState?.couponData?.discount);

          const couponAppliedPercent =
            100 - (couponAppliedAmt / parseFloat(totalPrice)) * 100;
          setpercentageDeducCoupon(couponAppliedPercent);
          // setamountDeducCoupon(parseFloat(totalPrice) - couponAppliedAmt);
          setamountDeducCoupon(
            parseFloat(
              parseFloat(parseFloat(totalPrice) - couponAppliedAmt).toFixed(2),
            ),
          );
        }

        // debugger;

        setcouponData(couponDataState.couponData);
      }
    }
  }, [couponDataState, totalPrice]);

  const customerId = useMemo(() => {
    return authUserData.isAuthenticated &&
      authUserData.UserData &&
      authUserData.UserData.id
      ? authUserData.UserData.id
      : '';
  }, [authUserData]);

  const handleApplyCoupon = (e) => {
    if (values.usedLoyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          coupon: 'Please remove loyalty points for using a coupon code',
        },
      }));
    }

    if (values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: 'Please remove store credit for using a coupon code',
        },
      }));
    }

    if (values.usedLoyaltyPoint !== '' && values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          coupon:
            'Please remove loyalty point and store credit points to add coupon code',
          storeCredit: '',
        },
      }));
    }

    // console.log('hehe values: ', values);

    if (values.usedLoyaltyPoint === '' && values.usedStoreCredit === '') {
      const current_date_time = getCurrentDateTime();
      if (values.errors.coupon === '') {
        const data = {
          coupon: values.coupon,
          merchant_id: merchantId,
          current_date_time: current_date_time,
          customer_id: customerId,
        };
        dispatch(fetchCouponData(data));
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
        }));
      }
    }
  };

  const handleRemoveCoupon = () => {
    dispatch(removeCoupon(''));
    setcouponData('');
    setValues((previousState) => ({
      ...previousState,
      coupon: '',
    }));
    setpercentageDeducCoupon(0);
  };

  const handleAddingTip = (mytip) => {
    setValues((previousState) => {
      return {
        ...previousState,
        tip: mytip,
        tipRemoved:
          mytip === '' &&
          !previousState.tipRemoved &&
          previousState.tipcustom === ''
            ? true
            : false,
      };
    });
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'coupon'
        ? event.target.value.toUpperCase()
        : fieldname === 'tipcustom'
        ? event.target.value
            .replace(/[^\d.]/g, '')
            .replace(/^(\d*\.)(.*)\./, '$1$2')
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2')
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'coupon':
        fieldValue.toUpperCase();
        cursorposition.coupon = newCursorPosition;
        validateCoupon(fieldValue, errors);
        break;
      case 'tip':
        cursorposition.coupon = newCursorPosition;
        validateCoupon(fieldValue, errors);
        break;
      case 'tipcustom':
        if (values.tipcustom === '') {
          values.errors.tipcustom = 'Please enter tip amount';
        } else {
          let inputStr = fieldValue.replace(/\D/g, ''); // Remove all non-digit characters for clean processing

          if (inputStr === '') {
            fieldValue = '0.00'; // Reset to default value if input is empty
          } else {
            // Remove leading zeros for clean numeric string
            inputStr = inputStr.replace(/^0+/, '');

            if (inputStr.length === 0) {
              fieldValue = '0.00';
            } else if (inputStr.length === 1) {
              fieldValue = '0.0' + inputStr;
            } else if (inputStr.length === 2) {
              fieldValue = '0.' + inputStr;
            } else {
              fieldValue =
                inputStr.slice(0, inputStr.length - 2) +
                '.' +
                inputStr.slice(-2);
            }
          }
        }
        cursorposition.tipcustom = event.target.selectionStart;
        break;

      default:
        break;
    }

    setValues((previousState) => ({
      errors,
      ...previousState,
      [fieldname]: fieldValue,
    }));
  };

  const handleStoreCredit = (event, grandtotal, storecredit, mytipamt) => {
    event.preventDefault();

    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'coupon'
        ? event.target.value.toUpperCase()
        : fieldname === 'tipcustom'
        ? event.target.value
            .replace(/[^\d.]/g, '')
            .replace(/^(\d*\.)(.*)\./, '$1$2')
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2')
        : fieldname === 'storeCredit'
        ? event.target.value
            // Remove extra dots and ensure only one dot exists at most
            .replace(/[^\d.]/g, '') // Allow digits and dots only
            .replace(/^(\d*\.)(.*)\./, '$1$2') // Remove extra dots
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2') // Remove extra dots after the decimal point
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'storeCredit':
        cursorposition.storeCredit = newCursorPosition;
        // --------------------------------------===
        let inputStr = fieldValue.replace(/\D/g, '');
        inputStr = inputStr.replace(/^0+/, '');

        if (inputStr.length === 0) {
          // fieldValue = "0.00";
        } else if (inputStr.length === 1) {
          fieldValue = '0.0' + inputStr;
        } else if (inputStr.length === 2) {
          fieldValue = '0.' + inputStr;
        } else {
          fieldValue =
            inputStr.slice(0, inputStr.length - 2) + '.' + inputStr.slice(-2);
        }
        if (
          parseFloat(fieldValue) > parseFloat(grandtotal - mytipamt).toFixed(2)
        ) {
          errors.storeCredit = `Store Credit can't be greater than $${parseFloat(
            grandtotal - mytipamt,
          ).toFixed(2)}`;
        } else if (fieldValue === '') {
          errors.storeCredit = '';
        } else if (parseFloat(fieldValue) > storecredit) {
          errors.storeCredit = "Credit can't be greater than your Store Credit";
        } else if (parseFloat(fieldValue) === 0) {
          errors.storeCredit = "Credit can't be 0.";
        } else {
          errors.storeCredit = '';
        }
        break;
      default:
        break;
    }

    setValues((previousState) => ({
      ...previousState,
      errors,
      [fieldname]: fieldValue,
    }));
    if (fieldValue === '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          loyaltyPoint: '',
        },
      }));
    }
  };

  const handleInputLoyaltyPoint = (
    event,
    subtotal,
    loyaltyPoint,
    couponDiscount,
    setCouponValue,
  ) => {
    event.preventDefault();

    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'loyaltyPoint'
        ? event.target.value
            // Remove extra dots and ensure only one dot exists at most
            .replace(/[^\d.]/g, '') // Allow digits and dots only
            .replace(/^(\d*\.)(.*)\./, '$1$2') // Remove extra dots
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2') // Remove extra dots after the decimal point
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'loyaltyPoint':
        cursorposition.loyaltyPoint = newCursorPosition;
        let inputStr = fieldValue.replace(/\D/g, '');
        inputStr = inputStr.replace(/^0+/, '');

        if (inputStr.length === 0) {
          // fieldValue = "0.00";
        } else if (inputStr.length === 1) {
          fieldValue = '0.0' + inputStr;
        } else if (inputStr.length === 2) {
          fieldValue = '0.' + inputStr;
        } else {
          fieldValue =
            inputStr.slice(0, inputStr.length - 2) + '.' + inputStr.slice(-2);
        }

        let newCart = [];

        if (bogoList.bogoList.length > 0) {
          newCart = handleBogoDeals(cartData, bogoList.bogoList);
        } else {
          newCart = [...cartData];
        }

        const newSubTotal = newCart
          .filter((item) => item.product.use_point === '1')
          .reduce((acc, curr) => {
            const couponPercentage = parseFloat(percentageDeducCoupon)
              ? parseFloat(percentageDeducCoupon)
              : 0;
            const newprice = curr.price - (curr.price * couponPercentage) / 100;
            return (acc = acc + newprice);
          }, 0);

        const total =
          parseFloat(newSubTotal) > 0 ? parseFloat(newSubTotal).toFixed(2) : 0;

        const eligibleToApplyLP = newCart.some(
          (item) => item?.product?.use_point === '1',
        );

        if (!eligibleToApplyLP) {
          errors.disableLoyaltyPoint = true;

          setTimeout(() => {
            setValues((prev) => ({
              ...prev,
              loyaltyPoint: '',
            }));
          }, 100);
        } else if (parseFloat(fieldValue) > parseFloat(total)) {
          errors.loyaltyPoint = `Loyalty Points can't be greater than $${parseFloat(
            total,
          ).toFixed(2)}`;
        } else if (fieldValue === '') {
          errors.loyaltyPoint = '';
        } else if (parseFloat(fieldValue) > loyaltyPoint) {
          errors.loyaltyPoint =
            "Points can't be greater than your loyalty points.";
        } else if (parseFloat(fieldValue) === 0) {
          errors.loyaltyPoint = "Points can't be 0.";
        } else {
          errors.loyaltyPoint = '';
        }
        break;
      default:
        break;
    }

    // console.log('fieldname: ', fieldname, ', fieldValue: ', fieldValue);

    setValues((previousState) => ({
      ...previousState,
      errors,
      [fieldname]: fieldValue,
    }));
    if (fieldValue === '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: '',
        },
      }));
      setCouponValue(true);
    }
  };
  const handleUseStoreCredit = () => {
    setValues((previousState) => ({
      ...previousState,
      usedStoreCredit: values.storeCredit,
    }));
    // setdiscountAmt(discountAmt + parseFloat(values.storeCredit));
  };

  // useEffect(() => {
  //   console.log('values: ', values);
  // }, [values]);

  useEffect(() => {
    if (
      cartData &&
      cartData.length > 0 &&
      values.loyaltyPoint &&
      values.usedLoyaltyPoint
    ) {
      const setLoyaltyPoint = () => {
        let newCart = [];
        if (bogoList.bogoList.length > 0) {
          const afterBogoCart = handleBogoDeals(cartData, bogoList.bogoList);
          newCart = [...afterBogoCart];
        } else {
          newCart = [...cartData];
        }

        const usePointProductsTotal = newCart
          .filter((item) => item.product.use_point === '1')
          ?.reduce((acc, curr) => {
            return (acc =
              parseFloat(acc) +
              parseFloat(getRoundValue(discountPrice(curr.price))));
          }, 0);

        // totalPrice
        // const percentageDeducted = parseFloat(
        //   (values.loyaltyPoint / parseFloat(usePointProductsTotal)) * 100,
        // ).toFixed(2);

        console.log('usePointProductsTotal: ', usePointProductsTotal);

        const percentageDeducted = parseFloat(
          (values.loyaltyPoint / parseFloat(usePointProductsTotal)) * 100,
        );

        console.log('percentageDeducted: ', percentageDeducted);

        setpercentageDeducLoy(percentageDeducted);
      };

      setLoyaltyPoint();
    }
  }, [
    bogoList,
    values.loyaltyPoint,
    cartData,
    percentageDeducCoupon,
    values.usedLoyaltyPoint,
  ]);

  const handleUseLoyaltyPoint = async () => {
    if (values.usedStoreCredit === '') {
      const eligibleToApplyLP = cartData.some(
        (item) => item?.product?.use_point === '1',
      );

      // handleInputLoyaltyPoint
      if (!eligibleToApplyLP) {
        setValues((prev) => ({
          ...prev,
          loyaltyPoint: '',
          errors: {
            ...prev.errors,
            disableLoyaltyPoint: true,
          },
        }));
        return;
      }

      setValues((previousState) => ({
        ...previousState,
        storeCredit: '',
        usedLoyaltyPoint: parseFloat(values.loyaltyPoint).toFixed(2),
      }));
      try {
        const data = {
          merchant_id: merchantId,
          customer_id: AuthUserState?.id,
          used_loyality_amt: values.loyaltyPoint,
        };

        const response = await axios.post(
          BASE_URL_SANDBOX + USED_LP_POINTS,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (response.data.status === 200) {
          setusedLoyaltyPointApiUserData(response.data);

          // setLoyaltyPoint();
          setdiscountAmt(discountAmt + parseFloat(values.loyaltyPoint));
        }
      } catch (error) {
        throw error;
      }
    }
    if (values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          loyaltyPoint:
            'To use loyality points first remove store credit used amount.',
          storeCredit: '',
        },
      }));
    }
  };

  const handleRemoveStoreCredit = (setCouponValue) => {
    setValues((previousState) => ({
      ...previousState,
      usedStoreCredit: '',
      storeCredit: '',
      errors: { ...previousState.errors, coupon: '' },
    }));

    if (values.coupon !== '') {
      setCouponValue(true);
    }

    if (values.errors.loyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: { ...previousState.errors, loyaltyPoint: '' },
      }));
    }
  };

  const handleRemoveLoyaltyPoint = (setCouponValue) => {
    setValues((previousState) => ({
      ...previousState,
      usedLoyaltyPoint: '',
      loyaltyPoint: '',
      errors: { ...previousState.errors, coupon: '' },
    }));
    if (values.coupon !== '') {
      setCouponValue(true);
    }

    setpercentageDeducLoy(0);
    setusedLoyaltyPointApiUserData('');
    setdiscountAmt(discountAmt - parseFloat(values.loyaltyPoint));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleApplyCoupon(e);
    }
  };
  return {
    handleInputChange,
    values,
    setValues,
    handleAddingTip,
    handleApplyCoupon,
    couponData,
    handleRemoveCoupon,
    handleKeyDown,
    orderSummaryData,
    handleStoreCredit,
    handleUseStoreCredit,
    handleRemoveStoreCredit,
    handleInputLoyaltyPoint,
    handleUseLoyaltyPoint,
    handleRemoveLoyaltyPoint,
    amountDeducCoupon,
    discountAmt,
  };
};

export default CouponTipSectionLogic;
